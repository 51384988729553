
import {
  parse,
  toDate,
  differenceInHours,
  differenceInSeconds,
  setHours,
  addDays,
  subDays,
  startOfHour,
  isValid,
} from 'date-fns';
const EIGHT_HOURS = 28800;

export default function businesshours (start, end) {
  if (typeof start === 'string') {
    const o = parse(start, 'yyyy-MM-dd HH:mm:ss', new Date());
    if (isValid(o)) {
      start = o;
    } else {
      start = toDate(start);
    }
  } else {
    start = toDate(start);
  }

  if (end) {
    if (typeof end === 'string') {
      const o = parse(end, 'yyyy-MM-dd HH:mm:ss', new Date());
      if (isValid(o)) {
        end = o;
      } else {
        end = toDate(end);
      }
    } else {
      end = toDate(end);
    }
  } else {
    end = new Date();
  }

  // if start time is after 5pm and today, return seconds between start and end.
  // This is an exception to allow for work done after hours
  if (start.getHours() > 17 && differenceInHours(new Date(), start) < 7) {
    return differenceInSeconds(end, start);
  }

  // if start time is after 5pm move it forward to next day.
  if (start.getHours() >= 17) {
    start = addDays(startOfHour(setHours(start, 9)), 1);

  // if start time is before 9am, move it forward to 9am
  } else if (start.getHours() < 9) {
    start = startOfHour(setHours(start, 9));
  }

  // if end time is after 5pm move it back to 5pm
  if (end.getHours() >= 17) {
    end = startOfHour(setHours(end, 17));

  // if end time is before 9am, move it to 5pm of the previous day
  } else if (end.getHours() < 9) {
    end = subDays(startOfHour(setHours(end, 17)), 1);
  }

  var total = 0;
  var timeRemainingDayOfStart, timeRemainingBetween;

  // while the difference between start and stop are more than 8 hours,
  // add the time remaining between start and the end of the day to the total,
  // and move start to the beginning of the next day
  while ((timeRemainingBetween = differenceInSeconds(end, start)) > EIGHT_HOURS) {
    timeRemainingDayOfStart = differenceInSeconds(startOfHour(setHours(start, 17)), start);

    total += timeRemainingDayOfStart;
    start = addDays(startOfHour(setHours(start, 9)), 1);
  }

  // add any remaining time to the total, unless end came after start.
  if (timeRemainingBetween > 0) {
    total += timeRemainingBetween;
  }

  return total;

}
