import PropTypes from 'prop-types';
import { createContext, useContext, useCallback, useState } from 'react';
import useSet from '@twipped/hooks/useSet';
import Pipeline from 'common/pipelines/pipeline';
import useMemoObject from '@twipped/hooks/useMemoObject';
import useGettableState from '@twipped/hooks/useGettableState';
import { observer } from 'mobx-react-lite';
import moment from "moment";

const PipelineViewContext = createContext(null);
PipelineViewContext.displayName = 'PipelineViewContext';

export const PipelineProvider = observer(function PipelineProvider ({
  pipeline,
  onPaginationChange,
  onSelect,
  children,
}) {

  const [ totalRows, setTotalRows ] = useState(0);
  const [ { pageLength, pageStart }, updatePagination, getPagination ] = useGettableState({
    pageStart: 0,
    pageLength: 50,
  });

  const setPagination = useCallback((values) => {
    const pagination = { ...getPagination(), ...values };
    updatePagination(pagination);
    if (onPaginationChange) onPaginationChange(pagination);
  });

  if (pipeline.filteredPipelines) {
    if (totalRows != pipeline.filteredPipelines.length) setTotalRows(pipeline.filteredPipelines.length);
  } else {
    if (totalRows != pipeline.length) setTotalRows(pipeline.length);
  }

  const sortFilteredPipelines = useCallback((id) => {
    const direction = pipeline.sortBy != id || pipeline.sortDirection < 0 ? 1 : -1;
    const pipelines = [...pipeline.filteredPipelines];

    pipelines.sort((a, b) => {
      function qs (a, b) {
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      }

      const column = pipeline.getColumn(id)
      const aMatch = column.sortValue(a);
      const bMatch = column.sortValue(b);
      for (let i = 0; i < aMatch.length; i++) {
        if (direction > 0 && !aMatch[i]) return -1;
        if (direction < 0 && !bMatch[i]) return -1;
        const res = qs(aMatch[i], bMatch[i]);
        if (res) return res * direction;
      }

      return 0;
    });

    pipeline.setSortDirection(direction)
    pipeline.setSortBy(id)
  });

  const updateTotalRows = useCallback((options) => {
    if (options) {
      setTotalRows(pipeline.filteredPipelines.length);
    } else {
      pipeline.sort()
      setTotalRows(pipeline.length);
    }
  });

  const selected = useSet();
  const onRowSelect = useCallback((id) => {
    if (selected.has(id)) selected.delete(id);
    else selected.add(id);

    onSelect && onSelect(Array.from(selected));
  }, [ selected ]);
  const allAreSelected = pipeline.length && selected.size === totalRows;

  const onColumnSelect = useCallback(() => {
    if (selected.size === pipeline.length) {
      selected.clear();
    } else {
      selected.add(pipeline.map((r) => r.id));
    }

    onSelect && onSelect(Array.from(selected));
  }, [ selected ]);


  const context = useMemoObject({
    pipeline,
    onColumnSelect,
    onRowSelect,
    selected,
    allAreSelected,
    updateTotalRows,
    sortFilteredPipelines,
    setPagination,
    pageLength,
    pageStart,
    totalRows,
  });

  return (
    <PipelineViewContext.Provider value={context}>{children}</PipelineViewContext.Provider>
  );
});
PipelineProvider.propTypes = {
  pipeline: PropTypes.instanceOf(Pipeline),
  onSelect: PropTypes.func,
  onPaginationChange: PropTypes.func,
};

export default function usePipelineViewContext () {
  return useContext(PipelineViewContext);
}
