
import { makeObservable, observable, computed, action, isObservable, observe, toJS } from "mobx";
import { createTransformer } from 'mobx-utils';
import Condition from 'common/condition';
import Column from './pipeline-column';
import { v4 as uuid } from 'uuid';
import { keyBy, isString, isFunction, find, clamp, isPrimitive } from 'common/utils';
import handybars from 'handybars';
import binSearch from 'binary-search';

const READ_ONLY = 'Pipeline is a read-only derived view and cannot be manipulated directly.';

export default class Pipeline {

  constructor ({ id, name, source, condition, columns, sortBy, link } = {}) {
    this.id = id || uuid();
    this.name = name || 'New Pipeline';
    this.source = source;
    this.datasource = null;
    this.condition = condition && new Condition(condition) || new Condition();
    this.columns = columns && columns.length && columns.map((c) => new Column(c)) || [];
    this.link = link || null;

    const sb = sortBy && find(this.columns, (c) => c.target === sortBy || c.id === sortBy) || this.columns[0];
    this.sortBy = sb && sb.id || null;
    this.sortDirection = 1;

    this._array = observable.array();
    this._disposers = new Map();

    makeObservable(this, {
      _filter: computed,
      _sorter: computed,
      _hbsLink: computed,
      refreshing: computed,
      empty: computed,


      bind: action,
      unbind: action,
      sort: action,
      _onSourceChange: action,
      _build: action,

      _array: observable,
      id: observable,
      name: observable,
      datasource: observable,
      source: observable,
      condition: observable.deep,
      columns: observable,
      link: observable,
      sortBy: observable,
      sortDirection: observable,
    });
  }

  bind (datasource) {
    this.datasource = datasource;
    this._disposer = observe(datasource.records, this._onSourceChange);
    this._build();
  }

  unbind () {
    this._disposer();
    this.datasource = null;
  }

  getColumn (id) {
    return find(this.columns, (c) => c.id === id);
  }

  getRow (id) {
    const found =  this._array.filter(x => x.id === parseInt(id))
    return found[0]
  }

  sort (column = null) {
    if (column) {
      if (!isString(column) && column && column.id) column = column.id;
      if (this.sortBy === column) {
        this.sortDirection = this.sortDirection > 0 ? -1 : 1;
      } else {
        this.sortBy = column;
      }
    } else {
      if (!this.sortBy)
        return
    }

    this._array.sort(this._sorter);
  }

  setSortBy (id) {
    this.sortBy = id
  }

  setSortDirection (direction) {
    this.sortDirection = direction
  }

  get size () {
    return this._array && this._array.length || 0;
  }

  get length () {
    return this._array && this._array.length || 0;
  }

  get empty () {
    return !this.datasource || !this.datasource.size;
  }

  get refreshing () {
    return this.datasource?.refreshing;
  }

  get _filter () {
    if (this.condition) {
      const f = this.condition.build();
      return createTransformer((r) => Number(f(r.json).result), { keepAlive: true });
    }
    return () => true;
  }

  get _sorter () {
    function qs (a, b) {
      if (a > b) return 1;
      if (b > a) return -1;
      return 0;
    }

    const columnsById = keyBy(this.columns, 'id');
    const column = this.sortBy && columnsById[this.sortBy];
    const direction = clamp(this.sortDirection || 1, -1, 1);

    if (!column) return (a, b) => qs(a.get('date.ordered'), b.get('date.ordered'));

    return (a, b) => {
      const aMatch = column.sortValue(a);
      const bMatch = column.sortValue(b);
      for (let i = 0; i < aMatch.length; i++) {
        if (direction > 0 && !aMatch[i]) return -1;
        if (direction < 0 && !bMatch[i]) return -1;
        const res = qs(aMatch[i], bMatch[i]);
        if (res) return res * direction;
      }

      return 0;
    };
  }

  href (record) {
    if (!this.link) return null;
    if (isFunction(this.link)) return this.link(record);
    if (isString(this.link)) return this._hbsLink(record.json);
  }

  get _hbsLink () {
    return handybars(this.link);
  }

  toJSON () {
    return toJS(this);
  }

  _onSourceChange = (change) => {
    const { object, type } = change;
    if (object !== this.datasource.records) throw new Error('Received change for something we are not observing?');

    switch (type) {
    case 'splice':
      change.removed.forEach((v) => this._delete(v));

      var values = change.added;
      if (this.sortBy) {
        values.forEach((v) => this._insert(v));
        return;
      }
      if (this.condition) values = values.filter(this._filter);
      this._array.push(...values);
      return;

    case 'add':
      return this._insert(change.newValue);

    case 'update':
      if (change.oldValue === change.newValue) return this._update(change.oldValue);
      this._delete(change.oldValue);
      this._insert(change.newValue);
      return;

    case 'delete':
      return this._delete(change.oldValue);

    // no default
    }
  };

  _dispose (value) {
    if (value && this._disposers.has(value)) this._disposers.get(value)();
    return this;
  }

  _observe (value) {
    if (isPrimitive(value)) return; // nothing needed to observe
    const target = isObservable(value) ? value : observable(value);
    const disposer = observe(target, () => this._update(value));
    this._disposers.set(value, disposer);
    return this;
  }

  _update (value) {
    const present = (!this.condition || this._filter(value));
    
    let idx, exists;

    // remove the item from the array so we can re-sort it if it needs to go back in
    while ((idx = this._array.indexOf(value)) > -1) {
      exists = true;
      this._array.splice(idx, 1); // remove it.
    }

    if (!present && exists) {
      // it's not supposed to be there anyway
      this._dispose(value);
      return this;
    }

    else if (present) {
      // item should be there, and is either missing or we just removed above to re-add it
      if (this.sortBy) {
        idx = this._place(value);
        this._array.splice(-(idx + 1), 0, value);
      } else {
        this._array.push(value);
      }
    }

    return this;
  }

  _insert (value) {
    if (!this.has(value) && !this.sortBy) {
      this._observe(value);
      this._array.push(value);
      return this;
    }
    const idx = this._place(value);
    if (!this.has(value) && idx < 0) {
      this._observe(value);
      this._array.splice(-(idx), 1, value); // insert into position
    } else if (!this.has(value)) {
      this._observe(value);
      this._array.splice(idx, 0, value);
    }

    return this;
  }

  _delete (value) {
    let idx;
    this._dispose(value);
    while ((idx = this._array.indexOf(value)) > -1) {
      this._array.splice(idx, 1); // remove it.
    }
    return this;
  }

  _place (value) {
    return binSearch(this._array, value, this._sorter);
  }

  _build () {
    const values = [];
    if (this.condition) {
      for (const v of this.datasource.values()) {
        if (this._filter(v)) values.push(v);
      }
    }
    if (this.sortBy) values.sort(this._sorter);
    this._array = observable.array(values);
  }

  has     (value)   { return this._array.includes(value); }
  get     (index)   { return this._array[index]; }

  add     () { throw new Error(READ_ONLY); }
  clear   () { throw new Error(READ_ONLY); }
  delete  () { throw new Error(READ_ONLY); }
}


const ALIASABLES = [
  'entries',
  'every',
  'find',
  'flat',
  'flatMap',
  'forEach',
  'indexOf',
  'join',
  'keys',
  'lastIndexOf',
  'map',
  'filter',
  'reduce',
  'slice',
  'some',
  'toString',
  'values',
  Symbol.iterator,
];

for (const alias of ALIASABLES) {
  Pipeline.prototype[alias] = function (...args) { return this._array[alias](...args); };
}

const REJECTABLES = [
  'add',
  'clear',
  'copyWithin',
  'delete',
  'fill',
  'pop',
  'push',
  'reverse',
  'shift',
  'splice',
  'unshift',
];

for (const alias of REJECTABLES) {
  Pipeline.prototype[alias] = function () { throw new Error(READ_ONLY); };
}
