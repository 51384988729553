
import { makeObservable, observable, toJS } from "mobx";
import { v4 as uuid } from 'uuid';
import { isString, isArray, isUndefinedOrNull, isFunction } from '@twipped/utils';
import businesshours from 'common/businesshours';

export default class Column {

  constructor (props) {
    if (isString(props)) {
      props = { target: props, ...Column.Defaults[props] };
    } else if (Column.Defaults[props.target]) {
      props = { target: props, ...Column.Defaults[props.target], ...props };
    }

    const { id, target, caption, icon, size, format, className, sortValue, viewport } = props;
    this.id = id || uuid();
    this.target = target;
    this.caption = caption;
    this.icon = icon;
    this.size = size || 1;
    this.viewport = viewport;
    this.format = format || 'Text';
    this.className = className;
    this.sortValueTarget = sortValue;

    makeObservable(this, {
      id: observable,
      target: observable,
      caption: observable,
      icon: observable,
      size: observable,
      format: observable,
      className: observable,
    });
  }

  sortValue (record) {
    var { sortValueTarget } = this;
    if (!sortValueTarget) {
      const val = record.get(this.target);
      return isUndefinedOrNull(val) ? [] : [ val ];
    }

    if (isFunction(sortValueTarget)) {
      const val = sortValueTarget(record);
      if (isUndefinedOrNull(val)) return [];
      return isArray(val) ? val : [ val ];
    }

    if (isString(sortValueTarget)) return c(record, sortValueTarget);
    if (isArray(sortValueTarget)) return c(record, ...sortValueTarget);
    return [];
  }

  toJSON () {
    return toJS(this);
  }
}

function c (source, ...args) {
  return args.map((arg) => source.get(arg));
}

Column.Defaults = {
  'check': {
    format: 'Check',
    size: '34px',
  },
  'disclose': {
    format: 'Disclose',
    size: '34px',
  },
  'id': {
    caption: 'Order ID',
    icon: 'Hashtag',
    size: '80px',
    format: 'Text',
    sortValue: (r) => [ r.id ],
  },
  'client.name': {
    caption: 'Client Name',
    size: 3,
    format: 'Text',
    justify: 'left',
    sortValue: (r) => c(r, 'client.name', 'date.ordered'),
  },
  'product.name': {
    caption: 'Product',
    size: 2,
    format: 'Text',
    justify: 'left',
    sortValue: (r) => c(r, 'product.name', 'date.ordered'),
  },
  'product.category': {
    caption: 'Product Type',
    size: '90px',
    format: 'Text',
    sortValue: (r) => c(r, 'product.category', 'product.name', 'date.ordered'),
  },
  'status': {
    caption: 'Order Status',
    size: 1,
    format: 'Text',
    sortValue: (r) => [ r.get('status.id') ],
  },
  'property.fulladdress': {
    caption: 'Subject Address',
    size: 2,
    format: 'AddressFull',
    justify: 'left',
    sortValue: (r) => c(r, 'property.address', 'property.address2', 'property.city', 'property.state', 'property.zipcode'),
  },
  'property.address': {
    caption: 'Address',
    size: 3,
    format: 'AddressSimple',
    justify: 'left',
    sortValue: (r) => c(r, 'property.address', 'property.address2'),
  },
  'property.city': {
    caption: 'Subject City',
    size: 2,
    style: { maxWidth: '150px' },
    justify: 'left',
    format: 'Text',
    sortValue: (r) => c(r, 'property.city', 'property.state', 'date.ordered'),
  },
  'property.state': {
    caption: 'State',
    size: '3em',
    format: 'Text',
    sortValue: (r) => c(r, 'property.state', 'property.city', 'date.ordered'),
  },
  'property.zipcode': {
    caption: 'Subject ZipCode',
    size: 1,
    format: 'PostalCode',
    sortValue: (r) => c(r, 'property.zipcode', 'property.zipcode_plus4', 'property.city'),
  },
  'property.county': {
    caption: 'Subject County',
    size: 1,
    format: 'Text',
    sortValue: (r) => c(r, 'property.county', 'property.state'),
  },
  'loanreference': {
    caption: 'Loan Reference Number',
    size: 2,
    format: 'Text',
    sortValue: (r) => c(r, 'loanreference'),
  },
  'project_name': {
    caption: 'Project Name',
    size: 2,
    format: 'Text',
    sortValue: (r) => c(r, 'project_name'),
  },
  'date.due': {
    caption: 'Due Date',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'date.due'),
  },
  'date.due_original': {
    caption: 'Original Due Date',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'date.due_original'),
  },
  'date.ordered': {
    caption: 'Date Ordered',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'date.ordered'),
  },
  'date.completed': {
    caption: 'Date Ordered',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'date.completed'),
  },
  'date.effective': {
    caption: 'Effective Date',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'date.effective'),
  },
  'date.last_touched': {
    caption: 'Last Touched Date',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => [ r.get('date.last_touched') || r.get('date.ordered') ],
  },
  'date.transaction': {
    caption: 'Transaction Date',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'date.transaction'),
  },
  'date.close': {
    caption: 'Close Date',
    size: '120px',
    format: 'Date',
    sortValue: (r) => c(r, 'date.close'),
  },
  'checklist.date': {
    caption: 'Checklist Completion Date',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'checklist.date'),
  },
  'checklist.status': {
    caption: 'Checklist Status',
    size: 2,
    format: 'Text',
    sortValue: (r) => c(r, 'checklist.status', 'checklist.date'),
  },
  'reconcile.date': {
    caption: 'Reconcile Request Date',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'reconcile.date'),
  },
  'reconcile.status': {
    caption: 'Reconcile Status',
    size: 1,
    format: 'Text',
    sortValue: (r) => c(r, 'reconcile.status', 'reconcile.date'),
  },
  'last_log.entry': {
    caption: 'Last Log Entry',
    size: 4,
    format: 'Text',
    sortValue: (r) => [ !!r.get('last_log.entry'), r.get('last_log.date') ],
  },
  'last_log.date': {
    caption: 'Last Log Date',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'last_log.date'),
  },
  'last_log.admin_name': {
    caption: 'Last Log By',
    size: 2,
    format: 'Text',
    sortValue: (r) => c(r, 'last_log.admin_name'),
  },
  'corrections.admin_name': {
    caption: 'QC Corrections Admin',
    size: 1,
    format: 'Text',
    sortValue: (r) => c(r, 'corrections.admin_name'),
  },
  'corrections.date_completed': {
    caption: 'QC Corrections Completed',
    size: '150px',
    format: 'DateTime',
    sortValue: (r) => c(r, 'corrections.date_completed'),
  },
  'invoiceamount': {
    caption: 'Invoice Amount',
    size: 1,
    format: 'Currency',
    sortValue: (r) => parseFloat(r.get('invoiceamount')),
  },
  'vendorfee': {
    caption: 'Vendor Fee',
    size: 1,
    format: 'Currency',
    sortValue: (r) => parseFloat(r.get('vendorfee')),
  },
  'netprofit': {
    caption: 'Net Profit',
    size: 1,
    format: 'Currency',
    sortValue: (r) => parseFloat(r.get('vendorfee')),
  },
  'unread_email': {
    caption: '# Unread Emails',
    icon: 'Envelope',
    size: '3em',
    format: 'Counter',
    sortValue: (r) => parseFloat(r.get('unread_email')),
  },
  'tags.icon': {
    caption: 'Tags',
    icon: 'Tag',
    size: '3em',
    format: 'TagsIcon',
    sortValue: (r) => c(r, 'tags.0.label', 'date.ordered'),
  },
  'tags.list': {
    caption: 'Tags',
    size: 4,
    format: 'TagsList',
    sortValue: (r) => c(r, 'tags', []).join(', '),
  },
  'admin_assigned.name': {
    caption: 'Order Admin',
    size: 1,
    format: 'Text',
    sortValue: (r) => c(r, 'admin_assigned.name'),
  },
  'qc_assigned.name': {
    caption: 'Order QC',
    size: 1,
    format: 'Text',
    sortValue: (r) => c(r, 'qc_assigned.name'),
  },
  'since.last_touched': {
    caption: 'Time Since Last Touched',
    icon: 'Timer',
    size: 1,
    format: 'TimeLastTouched',
    sortValue: (r) => [ Math.max(0, r.get('date.last_touched') ? businesshours(r.get('date.last_touched')) : businesshours(r.get('date.ordered'))), r.get('date.ordered') ],
  },
  'since.start_qc':  {
    caption: 'Time Since Starting QC',
    icon: 'StopWatch',
    size: 1,
    format: 'TimeStartQC',
    sortValue: (r) => [ Math.max(0, r.get('date.effective') ? businesshours(r.get('date.effective')) : 0), r.get('date.ordered') ],
  },
  'pipeline_status.unassign.vendora': {
    caption: 'A',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.unassign.vendora.status'),
  },
  'pipeline_status.unassign.vendorb': {
    caption: 'B',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.unassign.vendorb.status'),
  },
  'pipeline_status.unassign.vendorc': {
    caption: 'C',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.unassign.vendorc.status'),
  },
  'pipeline_status.inprogress.insp': {
    caption: 'I',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.inprogress.insp.class'),
  },
  'pipeline_status.inprogress.vendora': {
    caption: 'A',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.inprogress.vendora.status'),
  },
  'pipeline_status.inprogress.vendorb': {
    caption: 'B',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.inprogress.vendorb.status'),
  },
  'pipeline_status.inprogress.vendorc': {
    caption: 'C',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.inprogress.vendorc.status'),
  },
  'pipeline_status.qcready': {
    caption: 'QC',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.qcready.vendor.status'),
  },
  'pipeline_status.reconcile': {
    caption: 'Rec',
    size: '36px',
    format: 'PipelineStatus',
    sortValue: (r) => c(r, 'pipeline_status.reconcile.vendor.status'),
  },
  'locked': {
    caption: 'Locked',
    icon: 'SolidLock',
    size: '36px',
    format: 'Locked',
    sortValue: (r) => [ !!r.get('locked') ],
  },
};
